import * as React from "react";
import LayoutProjects from "./../../components/en/LayoutProjects";
import APropos from "./../../nav/en-projects/APropos";
import { Helmet } from "react-helmet";

const AProposPage = () => {
  return (
    <LayoutProjects>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABOUT US | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="mathiasbensimon,anamonso,ghostgalerie,parisexhibition,expositionparis,artcontemporainparis,contemporaryartgalleryparis" />
      </Helmet>
      <APropos />
    </LayoutProjects>
  );
};

export default AProposPage;
