import * as React from "react";
import {
  Wrapper,
  AboutUsWrapper,
} from "./APropos.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";

const APropos = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>À PROPOS</h1>
      <div className={AboutUsWrapper}>
      <br />
        <br />
        <br />
        <p style={{fontSize:"16px"}}>
        A new chapter in our history, Ghost Project wishes to highlight the emerging contemporary art scene. A project turned towards the future and towards the memory of tomorrow.</p>
        <br />
        <p style={{fontSize:"16px"}}>
        Emerging artists are the material of our future.</p>
        <br />
        <p style={{fontSize:"16px"}}>
        With this program, we wish to support the young creation which participates in the writing of the history of the future generations.
          </p>
       <br />
      </div>
    </div>
  );
};

export default APropos;
